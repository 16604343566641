@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
}

body {
    background: #313c74;
}

html {
    scrollbar-width: thin;
    scrollbar-color: #D4DFFF #313c74;
}

/* textarea {
    scrollbar-width: thin;
    scrollbar-color: #D4DFFF #21D6D6;
} */